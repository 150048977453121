import React from 'react';
import { Button, } from '@material-ui/core';
import { Router } from 'react-router-dom';
// const axios = require('axios');
const ss = "./image.jpg";


export default class DownloadAdmin extends React.Component {

  render() {
    return (
      <div>
        <div style={{ marginTop: '20px', marginBottom: '50px' }}>
          <h2>Download Speed Earn</h2>
        </div>

        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <img src={require('./image.jpeg')} alt="Speed Earn Update" width="200" height="450" />
        </div>

        <div>
          <a href="/downloadapp/speedearnadmin.apk" style={{ width: "200px", borderRadius: 5 }} className="button">Download Admin App</a>
        </div>
      </div>
    );
  }
}
