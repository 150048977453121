import React from 'react';
import swal from 'sweetalert';
import { Button, TextField } from '@material-ui/core';
const axios = require('axios');

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fullName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      tranPassword: '',
      confirmTransPassword: '',
      refCode: ''
    };
  }

  componentDidMount() {
    let index = window.location.href.indexOf('?');
    console.log('index', index)
    if (index > 0) {
      this.setState({
        refCode: window.location.href.substring(index + 1, window.location.href.length)
      })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  };

  register = async () => {
    const {/* fullName, email, */phone, password, confirmPassword, tranPassword, confirmTransPassword, refCode } = this.state;
    // console.log('fullName', fullName, fullName != '')
    // console.log('email', email)
    // console.log('phone', phone)
    // console.log('password', password)
    // console.log('confirmPassword', confirmPassword)
    // console.log('tranPassword', tranPassword)
    // console.log('refCode', refCode)

    // if (fullName == '') {
    //   swal({
    //     text: 'Invalid Name',
    //     icon: "error",
    //     type: "error"
    //   });

    //   return
    // }
    // if (email == '') {
    //   swal({
    //     text: 'Invalid Email',
    //     icon: "error",
    //     type: "error"
    //   });

    //   return
    // }
    if (isNaN(phone) || phone.length !== 11) {
      swal({
        text: 'Invalid Phone Number',
        icon: "error",
        type: "error"
      });

      return
    }
    if (password !== confirmPassword) {
      swal({
        text: 'Password did not match',
        icon: "error",
        type: "error"
      });

      return
    }
    if (tranPassword !== confirmTransPassword) {
      swal({
        text: 'Transaction Password did not match',
        icon: "error",
        type: "error"
      });

      return
    }
    if (password.length < 8 || tranPassword.length < 8) {
      swal({
        text: 'Minimum 8 Characters in Password Required',
        icon: "error",
        type: "error"
      });

      return
    }
    if (isNaN(refCode) || refCode.length !== 6) {
      swal({
        text: 'Invalid Referral Code',
        icon: "error",
        type: "error"
      });

      return
    }

    this.setState({ loading: true })
    try {
      const header = {
        "Content-Type": "application/json",
      }

      let body = {
        "name": this.state.fullName,
        "email": this.state.email,
        "phone": this.state.phone,
        "password": this.state.password,
        "transaction_password": this.state.tranPassword,
        "wallet_address": '',
        "referral_code": this.state.refCode
      }
      let res = await axios.post(`https://api.speed-earn.com/auth/signup`, body, { headers: header })

      if (res.status === 200) {
        swal({
          text: 'Successfully Registered',
          icon: "success",
          type: "success"
        });
      } else {
        swal({
          text: 'Something went wrong',
          icon: "error",
          type: "error"
        });
      }
    }
    catch (e) {
      swal({
        text: 'Something went wrong',
        icon: "error",
        type: "error"
      });
      console.log("err in signup api:", e);
      //showAlertMessage(localization.error, localization.something_went_wrong)
    }
    this.setState({ loading: false })
  }

  render() {
    return (
      <div>
        <div style={{ marginTop: '100px', marginBottom: '50px' }}>
          <h2>Register at Speed-Earn</h2>
        </div>

        <div>
          <TextField
            id="fullName"
            type="text"
            autoComplete="off"
            name="fullName"
            value={this.state.fullName}
            onChange={this.onChange}
            placeholder="Full Name"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="email"
            type="email"
            autoComplete="off"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            placeholder="Email"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="phone"
            type="text"
            autoComplete="off"
            name="phone"
            value={this.state.phone}
            onChange={this.onChange}
            placeholder="Phone"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="password"
            type="password"
            autoComplete="off"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
            placeholder="Password"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="confirmPassword"
            type="password"
            autoComplete="off"
            name="confirmPassword"
            value={this.state.confirmPassword}
            onChange={this.onChange}
            placeholder="Confirm Password"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="tranPassword"
            type="password"
            autoComplete="off"
            name="tranPassword"
            value={this.state.tranPassword}
            onChange={this.onChange}
            placeholder="Transaction Password"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="confirmTransPassword"
            type="password"
            autoComplete="off"
            name="confirmTransPassword"
            value={this.state.confirmTransPassword}
            onChange={this.onChange}
            placeholder="Confirm Transaction Password"
            style={{ width: "250px" }}
            required
          />
          <br /><br />
          <TextField
            id="refCode"
            type="text"
            autoComplete="off"
            name="refCode"
            value={this.state.refCode}
            onChange={this.onChange}
            placeholder="Referral Code"
            style={{ width: "250px" }}
            required
            disabled
          />



          <br /><br />
          <Button
            className="button_style"
            variant="contained"
            color="primary"
            size="large"
            style={{ width: "250px" }}
            disabled={
              this.state.fullName === '' ||
              this.state.email === '' ||
              this.state.phone === '' ||
              this.state.password === '' ||
              this.state.confirmPassword === '' ||
              this.state.tranPassword === '' ||
              this.state.confirmTransPassword === '' ||
              this.state.refCode === '' ||
              this.state.loading
            }
            onClick={this.register}
          >
            Register
          </Button>
        </div>
          <br /><br />
        <div>
          <a href="/downloadapp/speedearn.apk" style={{ width: "200px", borderRadius: 5 }} className="button">Download App</a>
        </div>
      </div>
    );
  }
}
