import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import Register from './Register';
import Downlaod from './Downlaod';
import DownlaodAdmin from './DownloadAdmin';
import "./style.css"

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path='/' component={Register} />
            <Route exact path='/register' component={Register} />
            <Route exact path='/download' component={Downlaod} />
            <Route exact path='/downloadadmin' component={DownlaodAdmin} />
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);